import React, { useEffect } from "react";
import { Checkout, Config } from "@cleeng/mediastore-sdk";
import adyenConfiguration from "Context/adyenConfiguration";
import { useSearchParams } from "react-router-dom";

const CheckOutWithoutTerms = ({ offer }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    Config.setDisablePaymentCheckbox();
  }, []);
  return (
    <div>
      <h1>Checkout (without terms)</h1>
      <button onClick={() => setSearchParams({ lng: "en2" })}>en2</button>
      <button onClick={() => setSearchParams({ lng: "en" })}>en</button>
      <div id="Sub-Component-Checkout">
        <Checkout
          offerId={offer}
          adyenConfiguration={adyenConfiguration}
          hideRedeemButton
          hideCaptcha={true}
        />
      </div>
    </div>
  );
};

export default CheckOutWithoutTerms;
